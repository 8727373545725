<template>
  <div class="church-presentation-template">
    <page-body class>
      <h1>{{ translations.tcAddGideonCardFacility }}</h1>
      <b-form validated>
        <section class="section-1 bg-block mb-4">
          <div class="body">
            <header class="toggle-header mb-3">
              <h2 class="font-style-1">{{ translations.tcLocation }}</h2>
              <ToggleArrow @click="Section1 = !Section1" section="Section1" :status="Section1"></ToggleArrow>
            </header>
            <div v-if="Section1">
              <b-row>
                <b-col sm="6">
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group class="form-element">
                        <div class="form_label">{{ translations.tcName }} *</div>
                        <div class="mb-3">
                          <b-form-input id="facility_name" v-model="facility.facility_name" class="flex-0" type="text">
                          </b-form-input>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group class="form-element">
                        <div class="form_label">{{ translations.tcAddressLine1 }}</div>
                        <div class="body">
                          <b-form-input id="address1" name="address1" v-model="address.address_line_1" class="flex-0"
                            type="text"></b-form-input>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group class="form-element">
                        <div class="form_label">{{ translations.tcAddressLine2 }}</div>
                        <div class="body">
                          <b-form-input id="address1" name="address1" v-model="address.address_line_2" class="flex-0"
                            type="text"></b-form-input>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="4" class="font-style-3">
                      <b-form-group class="form-element">
                        <div class="form_label">{{ translations.tcCity }}</div>
                        <div class="mb-3">
                          <b-form-input id="city" v-model="address.city" class="flex-0" type="text"></b-form-input>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col sm="4" class="font-style-3">
                      <b-form-group class="form-element">
                        <div class="form_label">{{ translations.tcState }}</div>
                        <div class="mb-3">
                          <b-form-select :title="translations.tcChurchState" v-if="!nonUS" v-model="state_key"
                            :options="optionsStates" @change="handleStateChange($event)"
                            class="g-select flex-0 mr-3 mb-3" required></b-form-select>
                          <b-form-input v-if="nonUS" :title="translations.tcChurchState" id="state-input"
                            :placeholder="translations.tcState" v-model="address.state"></b-form-input>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col sm="4" class="font-style-3">
                      <b-form-group class="form-element">
                        <div class="form_label">{{ translations.tcPostalCodeZip }}</div>
                        <div class="mb-3">
                          <b-form-input id="postal_code" v-model="address.postal_code" class="flex-0" type="text">
                          </b-form-input>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12" class="font-style-3">
                      <b-form-group class="form-element">
                        <div class="form_label">{{ translations.tcSelectCountry }}</div>
                        <div class="g-row mb-0 mb-sm-3">
                          <div class="g-col-1 d-flex mb-3 mb-sm-0">
                            <b-form-select :title="translations.tcChurchCountry" v-model="address.country_key"
                              :options="optionsCountries" class="g-select flex-0 mr-3 mb-3" required></b-form-select>
                            <template slot="first">
                              <option :value="null" disabled>{{ translations.tcSelect }}</option>
                            </template>
                          </div>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </div>
        </section>
        <section class="section-1 bg-block mb-4">
          <div class="body">
            <header class="toggle-header mb-3">
              <h2 class="font-style-1">{{ translations.tcCommunication }}</h2>
              <ToggleArrow @click="Section2 = !Section2" section="Section2" :status="Section2"></ToggleArrow>
            </header>
            <div v-if="Section2">
              <b-row>
                <b-col sm="6" class="font-style-3">
                  <b-form-group class="form-element">
                    <div class="form_label">{{ translations.tcPhone }}</div>
                    <div class="mb-3">
                      <b-form-input id="contact_phone" v-model="facility.phone" class="flex-0" type="text">
                      </b-form-input>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="6" class="font-style-3">
                  <b-form-group class="form-element">
                    <div class="form_label">{{ translations.tcEmail }}</div>
                    <div class="mb-3">
                      <b-form-input id="contact_email" v-model="facility.email" class="flex-0" type="text">
                      </b-form-input>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
        </section>
        <section class="section-3b bg-block mb-4">
          <div class="body">
            <header class="toggle-header mb-3">
              <h2 class="font-style-1">{{ translations.tcDetails }}</h2>
              <ToggleArrow @click="Section3 = !Section3" section="Section3" :status="Section3"></ToggleArrow>
            </header>
            <div v-if="Section3">
              <b-row>
                <b-col sm="6" class="font-style-3">
                  <b-form-group class="form-element">
                    <div class="form_label">{{ translations.tcFacilityType }} *</div>
                    <div class="g-row mb-0 mb-sm-3">
                      <div class="g-col-1 d-flex mb-3 mb-sm-0">
                        <b-form-select v-model="facility.facility_type_key" :options="optionsFacilityTypesTranslated"
                          @change="handleTypeChange($event)" class="form-control col-left-input g-select flex-0 mr-3">
                          <template slot="first">
                            <option :value="null" disabled>{{ translations.tcSelect }}</option>
                          </template>
                        </b-form-select>
                      </div>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
        </section>
        <section class="section-5 pt-3 pl-0 pl-sm-4">
          <div class="body">
            <div class="d-flex">
              <b-button variant="primary" @click="handleSaveClick(true)" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">{{
              translations.tcSaveAndAddContactPerson
              }}</b-button>
              <b-button variant="primary" @click="handleSaveClick(false)" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">{{
              translations.tcSave
              }}</b-button>
              <b-button variant="tertiary" @click="handleCancelClick" class="flex-0 w-100-sd mb-sm-0">{{
              translations.tcCancel
              }}</b-button>
            </div>
          </div>
        </section>
      </b-form>
    </page-body>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'
export default {
  name: 'card_facility_add',
  mixins: [translationMixin],
  data() {
    return {
      address: {
        address_line_1: '',
        address_line_2: '',
        country_key: null,
        city: '',
        state: '',
        postal_code: '',
      },
      contact: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
      },
      facility: {
        facility_name: '',
        phone: '',
        email: '',
        camp_org_key: '',
        ind_key: '',
        contact_ind_key: null,
        facility_type_key: '',
        facility_type_name: '',
        origin: 'GC'
      },
      findButton: '',
      number1: 0,
      optionsFacilityTypesTranslated: {},
      presetDate: null,
      searchedContact: [],
      Section1: true,
      Section2: true,
      Section3: true,
      selectChurch: [],
      state_key: '',
      statusKey: null,
      translations: {},
    }
  },
  methods: {
    ...mapActions({
      addFacility: 'card/addFacility',
      loadAddressFormOptions: 'membership/loadAddressFormOptions',
      loadProfile: 'card/getProfile',
      retrieveFacilityTypes: 'card/retrieveFacilityTypes',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedFacilityKey: 'user/setSelectedFacilityKey',
      setSelectedLocationContact: 'user/setSelectedLocationContact',
      setSelectedLocationKey: 'user/setSelectedLocationKey',
      setSelectedOrgTypeKey: 'user/setSelectedOrgTypeKey'
    }),
    handleStateChange(evt) {
      this.address.state = this.optionsStates.find((ch) => ch.value === evt).code
    },
    handleTypeChange(evt) {
      let name = this.facilityTypes
        .find((type) => type.value == evt)
        .display_text.replace(' ', '_')
        .toLowerCase()
      this.facility.facility_type_name = name
    },
    showModal(e, modalName) {
      this.findButton = !e.target ? e : e.target.name
      this.$root.$emit('bv::show::modal', `${modalName}`)
    },
    validateFacility() {
      if (this.facility.facility_name === '' || this.facility.facility_type_key === '') {
        return false
      }
      return true
    },
    validateContact() {
      if (
        this.contact.first_name === '' ||
        this.contact.last_name === '' ||
        this.contact.email === '' ||
        this.contact.phone === ''
      ) {
        return false
      }
      return true
    },
    validateAddress() {
      if (this.address.country_key === '' || this.address.state === '' || this.address.address_line_1 === '') {
        return false
      }
      return true
    },
    handleSaveClick(addcontact) {
      if (!this.validateFacility()) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcFacilityNameAndTypeRequired,
        })
        return
      }
      let facility = this.facility
      facility['address'] = this.address
      this.submitRequest(facility, addcontact)
    },
    async submitRequest(data, addcontact) {
      this.setLoadingStatus(true)
      let response = await this.addFacility(data)
      let pathname = 'card_profile'
      if (response != '') {
        await this.setSelectedFacilityKey(response.organization_key)
        await this.setSelectedLocationKey(response.organization_key)
        await this.setSelectedOrgTypeKey(this.facility.facility_type_key)
        if (addcontact) {
          await Promise.all([this.loadProfile(response.organization_key), this.setSelectedLocationContact(null)]).then(
            () => {
              pathname = 'card_facility_edit_contact'
            }
          )
        }
        this.setLoadingStatus(false)
        this.$router.push({ name: pathname })
        return
      }
      this.$swal({
        icon: 'error',
        text: this.translations.tcFacilityAddError,
      })
    },
    handleCancelClick() {
      this.$router.push({ name: 'gideon-card-facilities' })
    },
    async pageLoad() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          await this.loadAddressFormOptions(),
          await this.retrieveFacilityTypes(),
          await this.getViewTranslations(),
          await this.getComponentTranslations('common.placement-facility-types')
        ]).then(results => {
          this.facility.ind_key = this.userId
          this.facility.camp_org_key = this.userCampKey
          this.address.country_key = this.convertedAddressFormOptions.countriesByCamp[0].value

          this.stripReadableText(results[3])
          const translatedText = { common: { ...this.translations.common } }
          this.$set(this.translations, 'components', translatedText)

          this.translateDropdownOptions(
            [{ node: 'placement-facility-types', store: 'optionsFacilityTypes' }]
          )
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
  },
  async created() {
    this.pageLoad()
  },
  computed: {
    ...mapGetters({
      addressFormOptions: 'membership/addressFormOptions',
      convertedAddressFormOptions: 'membership/convertedAddressFormOptions',
      facilityTypes: 'card/getFacilityTypes',
      prefCulture: 'user/userPreferredCulture',
      userCampKey: 'user/userCampKey',
      userId: 'user/userId',
      userOrgKey: 'user/userOrgKey',
    }),
    churchName() {
      return !this.userSelectedChurchKey
        ? ''
        : this.churches.find((fn) => fn.chu_org_key === this.userSelectedChurchKey).church_name
    },
    optionsStates() {
      return [{ value: null, text: this.translations.tcState, disabled: true }, ...this.convertedAddressFormOptions.states_long]
    },
    optionsCountries() {
      return [
        { value: null, text: this.translations.tcSelectCountry, disabled: true },
        ...this.convertedAddressFormOptions.countries,
      ]
    },
    optionsFacilityTypes() {
      let data = [{ value: null, text: 'FacilityTypes', disabled: true }]
      let types = this.facilityTypes.map((type) => {
        return { value: type.value, text: type.display_text }
      })
      return types
    },
    nonUS() {
      return this.address.country_key !== 'bc4b70f8-280e-4bb0-b935-9f728c50e183'
    },
  },
  components: {
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    ToggleArrow: ToggleArrow,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

h3 {
  line-height: 1;
}

.form-group {
  margin-bottom: 0 !important;
}

.form_label {
  font-size: 14px !important;
  font-weight: 600;
}

.church_image {
  background-size: 100% 100%;
}

.remove_button {
  margin-top: 30px;
}

.check_label {
  position: relative;
  top: -40px;
  left: 30px;
  font-family: 'Open Sans';
}

hr {
  max-width: 671px;
  margin: 25px 0;
  background-color: #979797;
}

.btn.btn-outline,
.btn.btn-outline-primary {
  text-transform: uppercase;
  font-weight: 700;
}

input[type='number'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

.vdp-datepicker div input,
.vue__time-picker input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.vue__time-picker input.display-time {
  padding: 10px 20px !important;
  border: 1px solid #636363;
  width: 17em;
  font-weight: 600;
  height: 3.2em;
}

.bg-block {
  padding: 34px 32px;

  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.change-link {
  display: inline-flex;
  color: #118a0d;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.custom-checkbox {
  display: flex;
  height: 1.5rem;
  width: 55px;
  padding: 0;

  @include breakpoint(sm) {
    width: 24px;
  }

  input {
    order: 2;
  }

  label {
    position: relative;
    order: 1;
    height: 1.5rem;
    width: 1.5rem;

    &:before,
    &:after {
      height: 1.5rem;
      width: 1.5rem;
      left: 0;
      top: 0;
    }
  }
}

input[type='checkbox'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

textarea {
  height: 125px;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;

    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }

  .g-col-2 {
    max-width: 247px;
    width: 100%;

    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}

form {
  select.form-control.flex-1 {
    flex: 1 1 auto;
  }
}

.date-select-1 {
  .start-input {
    width: 287px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.time-select-1 {
  .start-time {
    width: 291px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.col-left-input {
  width: 291px !important;
}

.i-tooltip {
  position: relative;
  top: 0px;
  margin-left: 5px;
  line-height: 0;
  vertical-align: text-top;

  svg path {
    fill: #000;
  }
}

.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
